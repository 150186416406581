import React from 'react';
import { Typography, Paper, Box, Container } from '@mui/material';

const UnauthorizedMessage = () => {
    return (
        <Container maxWidth="sm" style={{marginTop: 30}}>
            <Paper elevation={3} style={{ padding: 16}}>
                <Box mb={2}>
                    <center>
                        <img src='/img/unauthorized.gif' alt="Unauthorized" style={{ maxWidth: '100%' }} />
                    </center>

                </Box>
                <center>
                    <Typography variant="h5" color="error" fontFamily='Arimo'>
                        Ah! Ah! Ah! Insufficient Permissions!
                    </Typography>
                </center>
                <Typography style={{margin:15}} fontFamily='Arimo'>
                    You did not say the magic word or you are not authorized to view this resource!
                </Typography>

                <Typography style={{margin:15}}  fontFamily='Arimo'>
                    You can reach out to <b>📧 support-automation@copadata.com</b> if you think this is a mistake.
                </Typography>
            </Paper>
        </Container>
    );
};

export default UnauthorizedMessage;