import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const TooltipDialog = ({ text, imageSrc, open, onClose }) => {

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent style={{padding:30}}>
                <Typography style={{ fontFamily: 'Arimo', fontSize: 18, fontWeight:'bold'}}>Explanation</Typography>
                <Typography style={{ fontFamily: 'Arimo'}}>{text}</Typography>

                <center>
                    <img src={imageSrc} alt="Screenshot"/>
                </center>

                <div style={{marginTop: 20, textAlign: 'center'}}>
                    <Button variant="contained"  style={{backgroundColor:'#202D4F'}} onClick={onClose}>
                        Okay, got it!
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TooltipDialog;
