import { removeUnwantedElements} from '../Helpers';
import { simulateLoading, uploadFilesToShare, processImageData } from '../UploadHelper';

export async function process_license_request(formData, files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, setOpenErrorUploadDialog) {

    let hasError = false;
    let createdTicketNumber = 0;

    // Reset values
    localStorage.removeItem("error");
    localStorage.removeItem("createdTicket");

    const requesterMail = localStorage.getItem("email");
    const requesterName = localStorage.getItem('name');

    if(!requesterMail || !requesterName){
        localStorage.setItem("error", `Ticket Creation failed because of missing identification!`);
        hasError = true; // Set the error variable to true
        setOpenErrorDialog(true);
        throw new Error(`Ticket Creation failed because of missing identification!`);
    }

    // Check if all required variables are defined
    const requiredVariables = [
        formData.subject,
        formData.description,
        requesterName,
        requesterMail,
    ];

    for (const variable of requiredVariables) {
        if (variable === undefined) {
            hasError = true; // Set the error variable to true
            localStorage.setItem("error", `Missing required values.`);
            setOpenProgressBar(false);
            setOpenErrorDialog(true);
            throw new Error(`Missing required values.`);
        }
    }

    try {

        // Create a JSON object for the request data
        const requestData = {
            ticket_type: "License Request",
            ticket_subject: "AR | " + formData.subject,
            priority: "Medium",
            urgency: "Medium",

            // Adjustment requested by Hannes
            customer_mail: requesterMail,
            customer_lastname: requesterName.split(' ').pop(),
            customer_firstname: requesterName.split(' ')[0],

            // Conditionally add ticket_short_id and ticket_full_id if present
            ...(formData.ticketData.ticketNumber && formData.ticketData.id ? {
                ticket_short_id: formData.ticketData.ticketNumber,
                ticket_full_id: formData.ticketData.id
            } : {}),

            description: formData.description,
            product_id: formData.productVersion,
            serial_number: formData.serialNumber,

            // Not needed anymore I think since we switched to Ticket Creator -> Agent but I leave it for now
            requesting_agent: requesterName,
            requester_mail: requesterMail,

            // Conditionally add fileshare_link if formData.ticketData.ticketNumber (base ticket) is present
            ...(formData.ticketData.ticketNumber ? {
                fileshare_link: formData.ticketData.ticketNumber > 14902 ?
                    `https://fileshare.copadata.com/apps/files/?dir=/Support%20Shares/${formData.ticketData.ticketNumber}` :
                    `https://fileshare.copadata.com/apps/files/?dir=/${formData.ticketData.ticketNumber}`
            } : {}),

            attachments: [],

            // Specific for this request
            article_number: formData.article_number,
            article_group: formData.article_base,
            license_problem_kind: formData.license_problem_kind,
        };

        // Completed here if no files exist.

        if (files.length === 0) {
            const progressBar = document.getElementById('progress-bar');
            const progressText = document.getElementById('progress-text');
            simulateLoading(progressBar, progressText);
        }

        processImageData(formData, requestData);
        console.log("Sending Data:", requestData);
        console.log(JSON.stringify(requestData));

        // Send the POST request as JSON
        const response = await fetch('https://fileapi.copatools.com/api/TicketData/forward-ar', {
            //const response = await fetch('https://localhost:7142/api/TicketData/forward-ar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
                'accept': 'application/json',
            },
            body: JSON.stringify(requestData), // Convert the object to JSON string
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data)
            const json = JSON.parse(data);
            localStorage.setItem("createdTicket", json.id);
            createdTicketNumber = parseInt(json.ticketNumber, 10);
        } else {
            const data = await response.text();
            console.log("Error:", data);

            if (response.status === 503) {
                localStorage.setItem("error", "The Zoho API seems to be unavailable " +
                    "at the moment. We are sorry for the inconvenience, please keep " +
                    "this window open and retry in a few minutes. Error 503 - Service unavailable.");
            } else {
                localStorage.setItem("error", `Ticket Creation failed with error ${data} at SPUBO API.`);
            }

            hasError = true;
            setOpenErrorDialog(true);
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        hasError = true;
        localStorage.setItem("error", `Ticket Creation failed with error ${error.message}`);
        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    localStorage.removeItem('ticketId');

    if (!createdTicketNumber) {
        if (!localStorage.getItem("error")) {
            localStorage.setItem("error", `No Ticket Number set, file upload could not be completed.`);
        }

        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    // Handle case when no files are present
    if (files.length === 0) {
        setOpenProgressBar(false);
        setOpenSuccessDialog(true);
        setOpenErrorDialog(false);
        return;
    } else {
        // Upload files if files are present
        await uploadFilesToShare(files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, formData.ticketData?.ticketNumber, createdTicketNumber, setOpenErrorUploadDialog);
    }



}
