import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import CategoryIcon from '@mui/icons-material/Category';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import { contentAndTemplatesServices, developmentServices } from './servicelist';

function ServiceSelector({ value, onChange }) {
    const getServiceHint = (service) => {
        if (!service) return null; // Check if service object is null

        return service.hint ? (
            <Typography variant="body2" color="textSecondary" style={{ marginTop: 5 }}>
                <InfoIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 4, marginTop: -4 }} />
                {service.hint}
            </Typography>
        ) : null;
    };

    return (
        <>
            <Select
                labelId="ps_service_kind-label"
                id="ps_service_kind"
                fullWidth
                value={value}
                onChange={onChange}
                startAdornment={(
                    <InputAdornment position="start">
                        <CategoryIcon />
                    </InputAdornment>
                )}
            >
                <ListSubheader>Content & Templates</ListSubheader>
                {contentAndTemplatesServices.map((service) => (
                    <MenuItem key={service.value} value={service.value}>{service.displayName}</MenuItem>
                ))}
                <ListSubheader>Development</ListSubheader>
                {developmentServices.map((service) => (
                    <MenuItem key={service.value} value={service.value}>{service.displayName}</MenuItem>
                ))}
            </Select>
            {getServiceHint(contentAndTemplatesServices.find((service) => service.value === value) || developmentServices.find((service) => service.value === value))}
        </>
    );
}

export default ServiceSelector;
