import React, { useState, useEffect, useRef } from 'react';
import { checkFileChanges, validateSerial, getResponseTime } from '../../Helpers/Helpers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { isValidDate } from 'dayjs';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import SubjectIcon from '@mui/icons-material/Subject';
import {CardActions, Divider, Grid, ListSubheader, Paper} from "@mui/material";
import TrixEditor from "../TrixEditor";
import Button from "@mui/material/Button";
import FileUpload from 'react-material-file-upload';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import ChildCareIcon from '@mui/icons-material/ChildCare';

// Import the handleButtonClick function from uploadFunctions.js
import {
    process_ps_request as handleServiceRequest
} from '../../Helpers/RequestProcessors/PSRequestProcessing';
import ProgressBarDialog from "../Dialogs/ProgressBarDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import ErrorDialog from "../Dialogs/ErrorDialog";
import VersionSelector from "../Statics/VersionSelector";
import SubsidiarySelector from "../Statics/SubsidarySelector";
import ServiceSelector from "../Statics/ServiceSelector";
import dayjs from "dayjs";
import 'dayjs/locale/de'; // Import German locale
import {process_ps_request} from "../../Helpers/RequestProcessors/PSRequestProcessing";
import UploadErrorDialog from "../Dialogs/UploadErrorDialog";

// Set the locale globally
dayjs.locale('de');

function PSRequestForm({ ticketData, fileshareData, profile }) {
    // Initialize the 'subject' state with the value from 'ticketData.subject' if it exists, or an empty string otherwise
    const [subject, setSubject] = useState(ticketData?.subject || '');
    const [productVersion, setProductVersion] = useState(ticketData?.productId || '');
    const [endCustomer, setEndCustomer] = useState('');
    const [subsidary, setSubsidary] = useState(ticketData?.subsidiary || '');
    const [cf_kind_of_professional_services, setServiceKind] = useState('');
    const [description, setDescription] = useState('');
    const [expectedSolution, setExpectedSolution] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [files, setFiles] = useState([]);
    const [prevFiles, setPrevFiles] = useState([]);
    const [openProgressBar, setOpenProgressBar] = useState(false);
    // Success Dialog open / close
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openErrorUploadDialog, setOpenErrorUploadDialog] = useState(false);
    const [images, setImages] = useState([]);

    const [progress, setProgress] = useState(0); // Add progress state

    // New state variables for date pickers
    const [desiredDeliveryDate, setDesiredDeliveryDate] = useState(null);
    const [desiredQuotationDate, setDesiredQuotationDate] = useState(null);

    const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);

    // Add a ref for the hidden input
    const imagesInputRef = useRef(null);

    const descriptionRef = useRef(null);
    const expectedSolutionRef = useRef(null);
    const additionalInfoRef = useRef(null);

    const handleFileChange = (newFiles) => {
        checkFileChanges(newFiles, prevFiles, setFiles, setPrevFiles);
    };

    const handleButtonClick = async () => {

        // Create an array to store the missing fields
        const missingFields = [];


        // Check if all fields are filled out
        const requiredFields = [
            { value: subject, name: 'Subject'},
            { value: endCustomer, name: 'End-Customer'},
            { value: productVersion, name: 'Product Version'},
            { value: description, name: 'Description'},
            { value: expectedSolution, name: 'Expected Solution'},
            { value: desiredDeliveryDate, name: 'Desired Delivery Date'},
            { value: desiredQuotationDate, name: 'Desired Quotation Date'},
            { value: cf_kind_of_professional_services, name: 'Service Kind'},
            { value: subsidary, name: 'Subsidiary'}
        ];

        requiredFields.forEach(field => {
            if (!field.value) {
                missingFields.push(field.name);
                if (field.setError) {
                    field.setError(true);
                }
            }
        });

        if (missingFields.length > 0) {
            // Log the missing fields to the console
            setOpenMissingFieldsDialog(true);
            alert('Please fill out the following fields:\n' + missingFields.join('\n'));
            console.log('Missing Fields: ', missingFields.join(', '));
            return;
        }

        // Show the progress bar dialog before starting the upload
        setOpenProgressBar(true);

        // Wait for the progress bar dialog to be fully opened
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second as an example

        // Handle file upload and progress updates here
        await handleServiceRequest(
            {
                subject,
                endCustomer,
                subsidary,
                cf_kind_of_professional_services,
                desiredDeliveryDate,
                desiredQuotationDate,
                productVersion,
                description,
                expectedSolution,
                additionalInfo,
                ticketData,
                images,  // Include images in the form data
            },
            files,
            setOpenProgressBar,
            setOpenSuccessDialog,
            setOpenErrorDialog,
            setOpenErrorUploadDialog,
        );
        // setOpenSuccessDialog(true);

    };

    const handleClose = () => {
        //setOpenSuccessDialog(false);
    };

    const handleCloseError = () => {
        setOpenErrorDialog(false);
    };

    const handleQuotationDateChange = (date) => {
        const selectedDate = dayjs(date);
        const minQuotationDate = dayjs().add(4, 'day');

        if (!selectedDate.isValid() || selectedDate.isBefore(minQuotationDate, 'day')) {
            // If the selected date is invalid or before the minimum quotation date, alert the user
            alert(`Please select a date that's at least 5 days in the future! Setting it to ${minQuotationDate.format('YYYY-MM-DD')}.`);

            // Automatically set the quotation date to 4 days in the future
            setDesiredQuotationDate(minQuotationDate);
        } else {
            // Update state with the selected date
            setDesiredQuotationDate(selectedDate);
        }
    };

    const handleDeliveryDateChange = (date) => {
        const selectedDate = dayjs(date);
        const baseDate = desiredQuotationDate ? dayjs(desiredQuotationDate) : dayjs().add(4, 'day');
        const minDeliveryDate = baseDate.add(7, 'day');

        if (!selectedDate.isValid() || selectedDate.isBefore(minDeliveryDate, 'day')) {
            // If the selected date is invalid or before the minimum delivery date, alert the user
            alert(`The delivery date needs to be at least 7 days after the quotation date! Setting it to ${minDeliveryDate.format('YYYY-MM-DD')}.`);

            // Automatically set the delivery date to 7 days after the base date
            setDesiredDeliveryDate(minDeliveryDate);
        } else {
            // Update state with the selected date
            setDesiredDeliveryDate(selectedDate);
        }
    };


    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };
    const handleEndCustomerChange = (event) => {
        setEndCustomer(event.target.value);
    };

    const handleSubsidaryChange = (event) => {
        setSubsidary(event.target.value); // Use event.target.value to get the selected value
    };

    const handleServiceKindChange = (event) => {
        setServiceKind(event.target.value); // Use event.target.value to get the selected value
    };

    const handleExpectedSolutionChange = (content) => {
        setExpectedSolution(content);
    };

    const handleAdditionalInfoChange = (content) => {
        setAdditionalInfo(content);
    };

    const handleProductVersionChange = (event) => {
        setProductVersion(event.target.value);
    };

    // Gets executed once at startup
    useEffect(() => {
        localStorage.removeItem('localFileData');
    }, []);

    // Convert the 'images' state to a JSON string
    const imagesJson = JSON.stringify(images);

    useEffect(() => {
        // Update the value of the ref
        imagesInputRef.current.value = imagesJson;
    }, [imagesJson]); // Run this effect whenever 'imagesJson' changes





    return (

        <form onSubmit={handleButtonClick}>

            <ProgressBarDialog
                open={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
                progress={progress}
                setProgress={setProgress} // Pass the setProgress function here
            />

            <Card style={{ marginBottom:100 }}>
                <center>
                    <Typography style={{ marginBottom: 20, marginTop: 30, fontFamily: 'Arimo', fontWeight: 'bold' }} variant="h5">
                        Professional Service Request
                    </Typography>
                    <Typography>Get assistance by our charming Professional Services Team.</Typography>
                </center>

                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Basic Information about the Service
                        </Typography>
                    </div>
                    <Grid style={{ margin: 20 }} container spacing={2}>
                        <Grid item xs={10} sm={11}>
                            <TextField
                                label="Subject *"
                                variant="outlined"
                                fullWidth
                                value={subject}
                                onChange={handleSubjectChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SubjectIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={11}>
                            <TextField
                                label="Name of the company placing the order (e.g. ABB) *"
                                variant="outlined"
                                fullWidth
                                value={endCustomer}
                                onChange={handleEndCustomerChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ChildCareIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <VersionSelector
                                productVersion={productVersion}
                                handleProductVersionChange={handleProductVersionChange}
                            />
                        </Grid>

                        <Grid item xs={10} sm={5}>
                            <SubsidiarySelector subsidary={subsidary} handleSubsidaryChange={handleSubsidaryChange} />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <FormControl fullWidth className="datetime_style">
                                <InputLabel htmlFor="desiredQuotationDate">Desired Quotation Date *</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="datepicker"
                                        id="desiredQuotationDate"
                                        value={desiredQuotationDate}
                                        onChange={handleQuotationDateChange}
                                        format="DD.MM.YYYY" // Set the desired format here
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={10} sm={5}>
                            <FormControl fullWidth className="datetime_style">
                                <InputLabel htmlFor="desiredDeliveryDate">Desired Delivery Date *</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="datepicker"
                                        id="desiredDeliveryDate"
                                        value={desiredDeliveryDate}
                                        onChange={handleDeliveryDateChange}
                                        format="DD.MM.YYYY" // Set the desired format here
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>



                    </Grid>

                </div>

                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Details about the needed Service
                        </Typography>

                    </div>

                    <div style={{ marginRight: 50, marginLeft: 50, marginBottom: 20, marginTop:10 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Typography style={{ marginTop: 8, fontFamily: 'Arimo', fontSize: 18 }} variant="h6">
                                Which Service do you need?
                            </Typography>

                                <Typography style={{fontFamily: 'Arimo', fontSize: 20 }} className="required-detail">*</Typography>

                        </div>

                        <Typography style={{ marginBottom: 10 }}>
                            You can select every service that is covered by the Professional Service Catalog. You can find the full catalog with details about the different services {' '}
                            <a href="data/Service_catalog_EN.pdf" target="_blank" rel="noopener noreferrer">
                                here
                            </a>
                            .
                        </Typography>

                        <ServiceSelector
                            value={cf_kind_of_professional_services}
                            onChange={handleServiceKindChange}
                        />

                    </div>


                    <TrixEditor
                        inputId="description_input"
                        label="Description"
                        description="Please give us an overview about the required service. Tell us about the current situation, what's the use-case / pain point and if there is a workaround currently."
                        onChange={handleDescriptionChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={descriptionRef}
                    />


                    <TrixEditor
                        inputId="expected-solution-input"
                        label="Expected Solution"
                        description="Outline the desired outcome of the requested Service."
                        onChange={handleExpectedSolutionChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={expectedSolutionRef}
                    />

                </div>

                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Files & Logs
                        </Typography>
                    </div>

                    <div class="file-upload-container" style={{marginLeft:50, marginRight:50, marginTop:10}}>


                        <Typography style={{ marginTop: 8, fontFamily: 'Arimo', fontSize: 18 }} variant="h6">
                            File Upload
                        </Typography>
                        <Typography style={{marginBottom: 10}}>Please provide all files that could be relevant to complete the requested service.</Typography>

                        {fileshareData.length > 0 && (  // Conditionally render the specific Box
                            <Box bgcolor="#357a38" p={5} m={2} borderRadius={'10px'} alignItems="center">
                                <Typography style={{ fontFamily: 'Arimo', color: 'white' }} variant="h6">
                                    Yey! Some files already exist for this request!
                                </Typography>
                                <Typography style={{ color: 'white' }}>
                                    The following files are already present in the existing Fileshare
                                    for the Ticket and do not need to be uploaded again. We will attach them to your
                                    request automatically:
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            {fileshareData.map((file, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ color: '#fff' }}>{file}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        <FileUpload
                            value={files}
                            maxSize={524288000}
                            onChange={handleFileChange}

                            buttonText="Select Files" title="Maximum Size: 500 MB / File. Drag 'n' drop some files here, or click to select files"/>

                        <input type="hidden" name="images" ref={imagesInputRef} />

                        <TrixEditor
                            inputId="additional-input"
                            label="File Description"
                            description="If you provide files, please give a brief overview of what we can find in them."
                            onChange={handleAdditionalInfoChange}
                            setImages={setImages}
                            images={images}
                            ref={additionalInfoRef}
                        />

                        <Box bgcolor="#f57c00" style={{marginBottom:20, margin:10}} p={5}  mt={2} borderRadius={'10px'} alignItems="center">
                            <Typography style={{color: 'white'}}><b>Info: </b> If you raise this request from an existing ticket, the files will be uploaded to the existing file share of the ticket: All these files will be  visible to the customer!
                                For ARs without a linked ticket, a new File Share will be created.
                                Please be aware that it can take some time that you can see the files in the file share.
                            </Typography>
                        </Box>

                    </div>
                </div>

                <CardActions>

                    <div style={{ marginLeft: 'auto', marginRight:45 }}>
                        <Button
                            style={{ backgroundColor: '#202D4F' }}
                            variant="contained"
                            onClick={handleButtonClick}
                        >
                            Request Service
                        </Button>
                    </div>

                </CardActions>

                <SuccessDialog open={openSuccessDialog} onClose={handleClose} />
                <UploadErrorDialog open={openErrorUploadDialog} onClose={handleClose}/>
                <ErrorDialog open={openErrorDialog} onClose={handleCloseError}/>

            </Card>
        </form>
    );
}

export default PSRequestForm;
