import React from "react";
import {connect} from "react-redux";

import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import Cookies from "js-cookie";

import {receiveAccessToken, receiveIdToken} from "../../actions/tokenActions";
import {receiveProfileData} from "../../actions/profileActions";

function parseQueryString(queryString) {
    if (!queryString) {
        return {}; // Return an empty object if there's no query string
    }

    // Split the query string into key-value pairs
    const pairs = queryString.substring(1).split('&');

    // Use Array.prototype.reduce to build the object
    const queryParams = pairs.reduce((params, pair) => {
        const [key, value] = pair.split('=');

        if (key && value) {
            // Decode the key and value, and assign them to the object
            params[decodeURIComponent(key)] = decodeURIComponent(value);
        }

        return params;
    }, {});

    return queryParams;
}

const AuthPage = ({profile, receiveProfile, receiveTokens, setProfile}) => {

    if (isEmpty(profile)) {

        const location = window.location;
        console.log("Location:", location);

        if (!location) {
            return "No Auth Parameters!"
        }

        const hash = location.hash;

        const response = parseQueryString(hash);

        if (response.error) {
            alert("Authentication failed! Error: " + response.error_description);
            return "Authentication Failed!"
        } else {

            if(response.access_token){
                receiveTokens(response.access_token, response.idToken);
                localStorage.setItem("access_token", String(response.access_token));

                // Remove file cache at login
                localStorage.removeItem('localFileData');

                // Set the secure HTTP-only cookie
                Cookies.set("access_token", response.access_token);

                try{
                    receiveProfile(jwtDecode(response.id_token));
                }
                catch{
                    alert("Error decoding your OneLogin access token.")
                }
            }

        }
        // Render loader
        return (
            <p style={{color:'#fff'}}>Validating Authentication...</p>
        );
    }

    else {
        if(profile.email){
            localStorage.setItem("email", profile.email);
        }

        if(profile.fullname){
            localStorage.setItem("name", profile.fullname);
        }

        if(profile.user_access_role){
            localStorage.setItem("user_access_role", profile.user_access_role);
            window.location.href = '/';
        }




        return "Profile Redirect..."
    }
};

const mapStateToProps = state => ({
    profile: state.profile,
    state: state.token.state,
    nonce: state.token.nonce
});

const mapDispatchToProps = dispatch => ({
    receiveProfile: data => dispatch(receiveProfileData(data)),
    receiveTokens: (accessToken, idToken) => {
        dispatch(receiveAccessToken(accessToken));
        dispatch(receiveIdToken(idToken));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPage);
