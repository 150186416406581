// Define the services for content and templates
export const contentAndTemplatesServices = [
    { value: "programming for zenon Logic", displayName: "Programming for zenon Logic", hint: "Based on specific customer requirements, COPA-DATA develops \n" +
            "functions, tasks, or complete zenon Logic projects. " },
    { value: "zenon Logic Code Review", displayName: "zenon Logic Code Review", hint: "With its experts for zenon Logic programming, COPA-DATA \n" +
            "supports you in analyzing existing code and identifying potential \n" +
            "optimizations." },
    { value: "Project template creation/optimization", displayName: "Project template creation / optimization", hint: "COPA-DATA actively supports you in the creation of reusable and \n" +
            "easily scalable zenon base projects/ template projects." },
    { value: "Project concept creation/validation", displayName: "Project concept creation / optimization", hint: "The zenon experts from COPA-DATA ensure that your current \n" +
            "project concept is aligned fully with your goals and unique \n" +
            "requirements, and corresponds to the basic philosophy of zenon \n" +
            "project configuration." },
    { value: "Project review/support", displayName: "Project review / support", hint: "Experienced zenon experts from COPA-DATA review your project \n" +
            "for efficiency and functionality." },
    { value: "Report creation", displayName: "Report creation", hint: "COPA-DATA actively supports you in the creation of reports used \n" +
            "with the zenon software platform. With existing projects and \n" +
            "reports, experts from COPA-DATA support you in optimizing for \n" +
            "an even more efficient use of the zenon software platform." },
    { value: "Report system configuration", displayName: "Report system configuration", hint: "COPA-DATA actively supports you in setting up a reporting system \n" +
            "with the zenon software platform.\n" +
            "If you already have an existing infrastructure, zenon experts from \n" +
            "COPA-DATA will support you in efficiently expanding your system" },
    { value: "Reporting concept support", displayName: "Reporting concept support", hint: "COPA-DATA actively supports you with creating or validating a \n" +
            "reporting concept with the zenon software platform. By using the \n" +
            "zenon software platform more efficiently, you will benefit from \n" +
            "reporting that is perfectly tailored to your needs." },
    { value: "Other PS CT", displayName: "Other", hint: "Don't know what to select? Select this option and describe your use case as detailed as possible!" }
];

// Define the services for development
export const developmentServices = [
    { value: "Code Optimization Workshop", displayName: "Code Optimization Workshop", hint: "Based on the results of a project review or project concept validation, " +
            "the opportunities for improvement are implemented together with experts from COPA-DATA." },
    { value: "Programming for zenon SE", displayName: "Programming for zenon Service Engine", hint: "COPA-DATA develops add-ins, controls or external extensions for \n" +
            "the Service Engine tailored to your requirements." },
    { value: "Wizard development for ES", displayName: "Wizard development for Engineering Studio", hint: "COPA-DATA creates an application or a wizard for you that \n" +
            "automates project creation and automatically updates existing \n" +
            "project configurations." },
    { value: "zenon API Code Review", displayName: "zenon AddIn Code Review", hint: "A COPA-DATA expert for Application Programming Interface (API) " +
            "solutions will assist you in analyzing the existing code and identifying vulnerabilities." },
    { value: "zenon API Support", displayName: "zenon AddIn Support", hint: "COPA-DATA supports you in the event of questions or " +
            "issues related to the zenon Application Programming Interface (API)." },
    { value: "zenon API Workshop/Training", displayName: "zenon API Workshop / Training", hint: "Based on a given use case, possible solutions are discussed and samples are developed " +
            "together with an expert from COPA-DATA." },
    { value: "Other PS DEV", displayName: "Other", hint: "Don't know what to select? Select this option and describe your use case as detailed as possible!"}
];

export function getServiceCategory(value) {
    if (contentAndTemplatesServices.some(service => service.value === value)) {
        return "PS-CT";
    } else if (developmentServices.some(service => service.value === value)) {
        return "PS-Dev";
    } else {
        return "PS-CT"; // Or handle this case according to your requirement
    }
}
