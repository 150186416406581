import React, { useState, useEffect, useRef } from 'react';
import { checkFileChanges, validateSerial, getResponseTime } from '../../Helpers/Helpers';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BuildIcon from '@mui/icons-material/Build';
import SubjectIcon from '@mui/icons-material/Subject';
import {CardActions, Checkbox, Divider, FormControlLabel, Grid, Paper} from "@mui/material";

import TrixEditor from "../TrixEditor";
import Button from "@mui/material/Button";
import FileUpload from 'react-material-file-upload';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

// Import the handleButtonClick function from uploadFunctions.js
import {
    process_technical_request as handleTechnicalRequest
} from '../../Helpers/RequestProcessors/TechnicalRequestProcessing';
import ProgressBarDialog from "../Dialogs/ProgressBarDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import UploadErrorDialog from "../Dialogs/UploadErrorDialog";
import ErrorDialog from "../Dialogs/ErrorDialog";
import VersionSelector from "../Statics/VersionSelector";
import PrioritySelector from "../Statics/PrioritySelector";
import Container from "@mui/material/Container";

function TechnicalRequestForm({ ticketData, fileshareData, profile }) {
    // Initialize the 'subject' state with the value from 'ticketData.subject' if it exists, or an empty string otherwise
    const [subject, setSubject] = useState(ticketData.subject || '');
    const [productVersion, setProductVersion] = useState(ticketData.productId || '');
    const [serialNumber, setSerialNumber] = useState(ticketData.serialNumber || '');
    const [build, setBuild] = useState(ticketData.build || '');
    const [priority, setPriority] = useState(ticketData.priority || 'Low');
    const [serialNumberError, setSerialNumberError] = useState(false);
    const [description, setDescription] = useState('');
    const [reproduce, setReproduce] = useState('');
    const [stepsDone, setStepsDone] = useState('');
    const [expectedSolution, setExpectedSolution] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [files, setFiles] = useState([]);
    const [prevFiles, setPrevFiles] = useState([]);
    const [openProgressBar, setOpenProgressBar] = useState(false);
    // Success Dialog open / close
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openErrorUploadDialog, setOpenErrorUploadDialog] = useState(false);
    const [images, setImages] = useState([]);


    // Checkbox state
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const [progress, setProgress] = useState(0); // Add progress state
    const [responseTime, setResponseTime] = useState('');
    const [subjectError, setSubjectError] = useState(false);
    const [productVersionError, setProductVersionError] = useState(false);
    const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);

    // Add a ref for the hidden input
    const imagesInputRef = useRef(null);


    const descriptionRef = useRef(null);
    const reproduceRef = useRef(null);
    const stepsDoneRef = useRef(null);
    const expectedSolutionRef = useRef(null);
    const additionalInfoRef = useRef(null);

    const handlePriorityChange = (event) => {
        const selectedPriority = event.target.value;
        setPriority(selectedPriority);
        setResponseTime(getResponseTime(selectedPriority));
    };

    const handleCheckboxChange = () => {
        setAgreedToTerms(!agreedToTerms);
    };


    const handleFileChange = (newFiles) => {
        checkFileChanges(newFiles, prevFiles, setFiles, setPrevFiles);
    };

    const handleButtonClick = async () => {

        // Check if the user has agreed to terms
        if (!agreedToTerms) {
            alert("Please make sure to agree to the prerequisite requirements to raise a technical request.")
            return;
        }

        // Create an array to store the missing fields
        const missingFields = [];

        // Check if all fields are filled out
        const requiredFields = [
            { value: subject, name: 'Subject', setError: setSubjectError },
            { value: productVersion, name: 'Product Version', setError: setProductVersionError },
            { value: description, name: 'Description'},
            { value: reproduce, name: 'Steps to Reproduce'},
            { value: stepsDone, name: 'Steps Already Done'},
            { value: expectedSolution, name: 'Expected Solution'}
        ];

        // Conditionally add serial number to required fields based on priority
        if (priority === 'High' || priority === 'Critical') {
            requiredFields.push({ value: serialNumber, name: 'End-User Serial Number', setError: setSerialNumberError });
        }

        requiredFields.forEach(field => {
            if (!field.value) {
                missingFields.push(field.name);
                if (field.setError) {
                    field.setError(true);
                }
            }
        });

        if (missingFields.length > 0) {
            // Log the missing fields to the console
            setOpenMissingFieldsDialog(true);
            alert('Please fill out the following fields:\n' + missingFields.join('\n'));
            console.log('Missing Fields: ', missingFields.join(', '));
            return;
        }

        // Show the progress bar dialog before starting the upload
        setOpenProgressBar(true);

        // Wait for the progress bar dialog to be fully opened
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second as an example

        // Handle file upload and progress updates here
        await handleTechnicalRequest(
            {
                productVersion,
                serialNumber,
                build,
                description,
                reproduce,
                stepsDone,
                expectedSolution,
                additionalInfo,
                subject,
                priority,
                ticketData,
                images,  // Include images in the form data
            },
            files,
            setOpenProgressBar,
            setOpenSuccessDialog,
            setOpenErrorDialog,
            setOpenErrorUploadDialog
        );
        // setOpenSuccessDialog(true);
    };

    const handleClose = () => {
        //setOpenSuccessDialog(false);
    };

    const handleCloseError = () => {
        setOpenErrorDialog(false);
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };

    const handleReproduceChange = (content) => {
        setReproduce(content);
    };

    const handleStepsDoneChange = (content) => {
        setStepsDone(content);
    };

    const handleExpectedSolutionChange = (content) => {
        setExpectedSolution(content);
    };

    const handleAdditionalInfoChange = (content) => {
        setAdditionalInfo(content);
    };

    const handleProductVersionChange = (event) => {
        setProductVersion(event.target.value);
    };

    // Validate Serial Number
    const handleSerialNumberChange = (event) => {
        const inputSerialNumber = event.target.value;

        // Call the validateSerial function
        validateSerial(inputSerialNumber, setSerialNumber, setSerialNumberError);
    };

    const handleBuildChange = (event) => {
        const inputBuild = event.target.value;

        if(!inputBuild){
            setBuild('');
        }

        // Validate if the input is a positive number above 0
        if (!/^\d+$/.test(inputBuild) || parseInt(inputBuild, 10) <= 0) {
            alert("Only digits are allowed in the build request number.")
        }

        else {
            setBuild(inputBuild);
        }
    };

    // Gets executed once at startup
    useEffect(() => {
        setResponseTime(getResponseTime(priority));
        localStorage.removeItem('localFileData');
    }, []);

    // Convert the 'images' state to a JSON string
    const imagesJson = JSON.stringify(images);

    useEffect(() => {
        // Update the value of the ref
        imagesInputRef.current.value = imagesJson;
    }, [imagesJson]); // Run this effect whenever 'imagesJson' changes


    return (

        <form onSubmit={handleButtonClick}>

            <ProgressBarDialog
                open={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
                progress={progress}
                setProgress={setProgress} // Pass the setProgress function here
            />

            <Card style={{ marginBottom:100 }}>
                <center>
                    <Typography style={{ marginBottom: 20, marginTop: 30, fontFamily: 'Arimo', fontWeight: 'bold' }} variant="h5">
                        Technical Support Request
                    </Typography>
                    <Typography style={{fontFamily: 'Arimo', fontWeight: 'bold'}}>Before you start filling in please consider the following criteria:</Typography>
                </center>

                <Container>
                    <Typography style={{fontFamily: 'Arimo',marginRight:60, marginLeft:50}}>
                        <ul>
                            <li style={{ marginBottom: 10 }}>
                                Assistant requests are <b>collaborative</b> and not to be used to outsource local support; the local agent remains responsible for the originating ticket.
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                The system version must be under <b>maintenance</b> <a style={{textDecoration:'none'}} href="http://corporate.copa-data.internal/AboutProduct/Lists/zenon%20Versions%20Lifecycle/product%20lifecylce%20new.aspx">(product life cycle)</a> or have a license under <b>long-term support</b>.
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                All relevant <b>information and actions</b> defined in the <a style={{textDecoration:'none'}} href="https://firestart.copadata.com/?modelid=b65cc02a-ef7f-4fa6-b1b2-0b7c763f5ae2&type=OverviewOperation&view=v_flowchart">workflows</a> must be provided. This includes <b>thorough research</b> using available resources such as product documentation, self-service search, Zoho tickets, engineering assistant, corporate search center, and DevOps search.
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                Ideally, the issue should be <b>reproducible</b> or <b>access to the affected system</b> should be available. If neither is possible, all relevant <b>logs and data</b> (e.g. SIC full log, project backup, project data) must be provided.
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                Only low-level <b>API questions</b> are processed; all other API requests must be submitted as Professional Service Assistant Requests.
                            </li>
                        </ul>
                    </Typography>
                </Container>




                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Basic Ticket Information
                        </Typography>
                    </div>
                    <Grid style={{ margin: 20 }} container spacing={2}>
                        <Grid item xs={10} sm={11}>
                            <TextField
                                label="Subject *"
                                variant="outlined"
                                fullWidth
                                value={subject}
                                onChange={handleSubjectChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SubjectIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={5}>
                            <VersionSelector
                                productVersion={productVersion}
                                handleProductVersionChange={handleProductVersionChange}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <TextField
                                label="End-User Serial Number (mandatory for high & critical requests)"
                                variant="outlined"
                                fullWidth
                                value={serialNumber}
                                onChange={handleSerialNumberChange}
                                error={serialNumberError} // Add error state
                                helperText={serialNumberError ? 'Expected Format: C00XX-XXXXX-XXXXX-XXXXX-XXXXX' : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <VpnKeyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={5}>
                            <TextField
                                label="Build"
                                variant="outlined"
                                fullWidth
                                value={build}
                                onChange={handleBuildChange}
                                style={{ width: '100%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BuildIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <PrioritySelector priority={priority} handlePriorityChange={handlePriorityChange} responseTime={responseTime} setResponseTime={setResponseTime} />
                    </Grid>

                </div>

                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Ticket Details
                        </Typography>

                    </div>


                    {/* Encapsulate each section with the TrixEditor component */}
                    <TrixEditor
                        inputId="description_input"
                        label="Description"
                        description="Please let us know what's the current situation on the issue, be as detailed as possible to speed up the process!"
                        onChange={handleDescriptionChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={descriptionRef}
                    />

                    <TrixEditor
                        inputId="reproduce-input"
                        label="Steps to Reproduce"
                        description="Detailed, step-by-step instructions that can consistently recreate the reported issue, helping our team pinpoint and resolve the problem effectively."
                        onChange={handleReproduceChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={reproduceRef}
                    />

                    <TrixEditor
                        inputId="steps-done-input"
                        label="Steps already done"
                        description="Please provide a concise summary of the actions or steps that have already been taken to address the issue in this ticket."
                        onChange={handleStepsDoneChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={stepsDoneRef}
                    />

                    <TrixEditor
                        inputId="expected-solution-input"
                        label="Expected Solution"
                        description="Outline the desired outcome or resolution for the reported issue."
                        onChange={handleExpectedSolutionChange}
                        required="true"
                        setImages={setImages}
                        images={images}
                        ref={expectedSolutionRef}
                    />

                    <TrixEditor
                        inputId="additional-input"
                        label="Additional Information"
                        description="Provide any extra context or relevant details that may aid in the understanding and resolution of the issue."
                        onChange={handleAdditionalInfoChange}
                        setImages={setImages}
                        images={images}
                        ref={additionalInfoRef}
                    />

                </div>

                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Files & Logs
                        </Typography>
                    </div>

                    <div class="file-upload-container" style={{margin:20}}>
                        <Typography>Please provide all files that already were captured about this issue. Especially think of:</Typography>

                        <ul>
                            <li><Typography><b>System Information Collector Logs</b></Typography></li>
                            <li><Typography>Project Backup</Typography></li>
                            <li><Typography>Diagnosis Viewer Logs</Typography></li>
                            <li><Typography>Wireshark Logs</Typography></li>
                            <li><Typography>Other files that may be relevant</Typography></li>
                        </ul>

                        {fileshareData.length > 0 && (  // Conditionally render the specific Box
                            <Box bgcolor="#357a38" p={5} m={2} borderRadius={'10px'} alignItems="center">
                                <Typography style={{ fontFamily: 'Arimo', color: 'white' }} variant="h6">
                                    Yey! Some files already exist for this request!
                                </Typography>
                                <Typography style={{ color: 'white' }}>
                                    The following files are already present in the existing Fileshare
                                    for the Ticket and do not need to be uploaded again. We will attach them to your
                                    request automatically:
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            {fileshareData.map((file, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ color: '#fff' }}>{file}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        <FileUpload
                            value={files}
                            maxSize={524288000}
                            onChange={handleFileChange}

                            buttonText="Select Files" title="Maximum Size: 500 MB / File. Drag 'n' drop some files here, or click to select files"/>

                        <input type="hidden" name="images" ref={imagesInputRef} />

                        <Box  bgcolor="#f57c00" p={5} mt={2} borderRadius={'10px'} alignItems="center">
                            <Typography style={{color: 'white'}}><b>Info: </b> If you raise this request from an existing ticket, the files will be uploaded to the existing file share of the ticket: All these files will be  visible to the customer!
                                For ARs without a linked ticket, a new File Share will be created.
                                Please be aware that it can take some time that you can see the files in the file share.
                            </Typography>
                        </Box>
                        {/* Display the table below the file upload section */}


                    </div>

                </div>

                <Box bgcolor="#EEEEEE" style={{paddingLeft:50, paddingRight:50, padding:20, margin:50, marginBottom:20, }} borderRadius={'10px'} alignItems="center">
                    <Typography style={{fontFamily:'Arimo', marginBottom:5}}><b>Please agree before completing your request:</b></Typography>
                    <FormControlLabel
                        style={{fontFamily:'Arimo'}}
                        control={
                            <Checkbox
                                checked={agreedToTerms}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="This support request contains all the essential information for the supporter to fully understand the situation. Prior to reaching out, I consulted with local colleagues, conducted thorough research, and made efforts to investigate. I have ensured compliance with the criteria for support requests and priorities."
                    />
                </Box>



                <CardActions>

                    <div style={{ marginLeft: 'auto', marginRight:45 }}>
                        <Button
                            style={{ backgroundColor: '#202D4F', color: agreedToTerms ? '#ffffff' : '#777777' }}
                            variant="contained"
                            onClick={handleButtonClick}
                            disabled={!agreedToTerms}
                        >
                            Request Assistance
                        </Button>
                    </div>

                </CardActions>

                <SuccessDialog open={openSuccessDialog} onClose={handleClose} />
                <UploadErrorDialog open={openErrorUploadDialog} onClose={handleClose}/>
                <ErrorDialog open={openErrorDialog} onClose={handleCloseError}/>

            </Card>
        </form>
    );
}

export default TechnicalRequestForm;
