import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

const ErrorDialog = ({ open, onClose }) => {
    const errorLog = localStorage.getItem("error");

    const resendRequest = () => {
        // Add your logic for resending the request here
        // For now, let's just log a message
        console.log("Resending request...");
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <div style={{ display: 'flex' }}>
                <img
                    src="img/error.png"
                    alt="Error Icon"
                    style={{ marginRight: '10px', height:'150px', width: '150px', padding: 25 }}
                />
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', marginBottom: 10 }} variant="h6">
                            Error!
                        </Typography>

                    </div>
                    <Typography>
                        We encountered an error while processing your request.
                        Make sure that your internet connection is stable.
                    </Typography>
                    <Typography style={{ marginTop: 10 }}>
                        You can try again to send the request. If this does not help, please reach out to
                    </Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>cs-tools@copadata.com</b>
                    </Typography>
                    <Button style={{ margin: '10px', alignSelf: 'flex-end' }} variant="outlined" color="primary" onClick={onClose}>
                        Try again
                    </Button>

                </DialogContent>
            </div>
            <div style={{ margin: 15, padding: '10px', borderRadius: '5px', background: '#f2f2f2', fontFamily: 'Courier New', wordWrap: 'break-word' }}>
                <p>
                    <b>Error Log</b>
                </p>
                <Typography style={{ fontFamily: "Courier New", background: '#f2f2f2' }}>{errorLog}</Typography>

                <CopyToClipboard text={errorLog}>
                    <Button style={{marginTop: 10}} variant="outlined" color="primary">
                        <span role="img" aria-label="Copy">📋</span> Copy Log to Clipboard
                    </Button>
                </CopyToClipboard>


            </div>
        </Dialog>
    );
};

export default ErrorDialog;
