import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";

function ProgressBarDialog({ open, onClose}) {
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <center>

                    <img alt="Loading" height="250" src="img/loading-face.gif"/>

                    <DialogTitle>
                        Processing...
                    </DialogTitle>

                <DialogContent>

                    <Typography style={{paddingLeft:50, paddingRight:50}}>
                        Please bear with us while we process your request and upload your files.
                    </Typography>

                    <div className="progress-wrapper">
                        <progress id="progress-bar" value="0" max="100"> 0% </progress>
                        <center><p id="progress-text">0 %</p></center>
                    </div>
                </DialogContent>
                </center>
            </Dialog>
        </div>
    );
}

export default ProgressBarDialog;
