import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'trix';
import 'trix/dist/trix.css';

import Typography from "@mui/material/Typography";

function TrixEditor({ inputId, label, description, onChange, onAttachmentAdd, required, setImages, images}) {
    const trixEditorRef = useRef(null);


    useEffect(() => {
        const trixEditor = trixEditorRef.current;

        if (trixEditor) {
            const handleChange = (event) => {
                if (onChange) {
                    onChange(event.target.innerHTML);
                }
            };

            const handleFileAccept = (event) => {
                // Only allow image upload
                const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
                if (!acceptedTypes.includes(event.file.type)) {
                    event.preventDefault();
                    alert("We only support jpeg, png, or gif image files to paste in this form.");
                }

            };

            const handlePaste = (event) => {
                try {
                    const pastedContent = event.clipboardData.getData('text/html');
                    console.log("Pasted content:", pastedContent); // Log the pasted content to the console for inspection

                    // Check if the pasted content contains base64 image data
                    const isBase64 = pastedContent && pastedContent.includes('data:image');

                    if (isBase64) {
                        event.preventDefault();
                        alert("Pasting images directly from Teams is not supported. You know this error from WhatsApp Web, don't you?");
                    }
                } catch {
                    // Nobody cares it's fine
                }
            };

            const handleAttachmentAdd = (event) => {
                const attachment = event.attachment;

                if (attachment.file) {
                    handleAttachmentAddLocal(attachment);
                }
            };

            trixEditor.addEventListener('trix-change', handleChange);
            trixEditor.addEventListener('trix-file-accept', handleFileAccept);
            trixEditor.addEventListener('paste', handlePaste); // Add paste event listener

            trixEditor.addEventListener('trix-attachment-add', handleAttachmentAdd);

            return () => {
                // Remove the event listeners when the component is unmounted
                trixEditor.removeEventListener('trix-change', handleChange);
                trixEditor.removeEventListener('trix-file-accept', handleFileAccept);
                trixEditor.removeEventListener('paste', handlePaste); // Remove paste event listener
                trixEditor.removeEventListener('trix-attachment-add', handleAttachmentAdd);
            };
        }
    }, [onChange, onAttachmentAdd]);

    const handleAttachmentAddLocal = (attachment) => {

        if (images.length >= 10) {
            alert("Maximum number of images reached. You can only upload up to 10 images.");
            return;
        }

        const fileReader = new FileReader();

        fileReader.onload = (e) => {


            const fileContent = e.target.result;

            const fileName = attachment.file.name;
            const fileType = attachment.file.type;

            // Generate a unique name for the file with the actual filename and file extension
            const uniqueName = `${Date.now()}_${Math.random().toString(36).substring(7)}_${fileName}`;

            // Get the blob name from the attachment
            const blobName = attachment.attachment.previewURL;

            // Create a new image object
            const newImage = { uniqueName, fileContent, blobName };

            // Check if adding the new image would exceed the maximum JSON size
            const currentImages = [...images, newImage];
            const jsonString = JSON.stringify(currentImages);

            const jsonSizeInMB = new Blob([jsonString]).size / (1024 * 1024); // Convert bytes to megabytes

            if (jsonSizeInMB > 10) {
                alert(`Adding the new image would exceed the maximum attachment size of 10 MB. Please upload it to the Fileshare instead.`);
                return;
            }

            // Update the 'images' state in your component
            setImages((prevImages) => [
                ...prevImages,
                newImage, // Include additional details if needed
            ]);

        };

        // Read the attachment file
        fileReader.readAsDataURL(attachment.file);
    };

    return (
        <>
        {label === 'File Description' ? ( // Render the whole div if label is label1
            <div>
                <div style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography style={{ marginTop: 8, fontFamily: 'Arimo', fontSize: 18 }} variant="h6">
                            {label}
                        </Typography>
                        {required && (
                            <Typography style={{fontFamily: 'Arimo', fontSize: 20 }} className="required-detail">*</Typography>
                        )}
                    </div>
                    <Typography style={{ marginBottom: 10 }}>{description}</Typography>

                    <input type="hidden" id={inputId} />

                    <trix-editor required="required" input={inputId} ref={trixEditorRef} />
                </div>
            </div>
        ) : ( // Render another div if label is not 'File Description'
            <div style={{ marginRight: 50, marginLeft: 50, marginBottom: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography style={{ marginTop: 8, fontFamily: 'Arimo', fontSize: 18 }} variant="h6">
                        {label}
                    </Typography>
                    {required && (
                        <Typography style={{fontFamily: 'Arimo', fontSize: 20 }} className="required-detail">*</Typography>
                    )}
                </div>
                <Typography style={{ marginBottom: 10 }}>{description}</Typography>

                <input type="hidden" id={inputId} />

                <trix-editor required="required" input={inputId} ref={trixEditorRef} />
            </div>
        )}
        </>
);
}

TrixEditor.propTypes = {
    inputId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onAttachmentAdd: PropTypes.func,  // Add this prop for handling attachment events
    progressElement: null,
    //required: PropTypes.bool,  // Correct the prop type for consistency
    //attachments: false,
};


export default TrixEditor;
