import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardMedia } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog'; // Import Dialog component
import { Provider } from "react-redux";
import store from "./reducers/_store";
import Cookies from "js-cookie";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { useDropdownLogic } from './dropdownLogic';
import Footer from "./Components/Footer";
import { fetchBaseTicketData, fetchFileshareData } from './Helpers/GetBaseTicketData';
import LoginPage from "./Components/Auth/LoginPage";
import HomeContainer from "./Components/HomeContainer";
import AuthPage from "./Components/Auth/AuthPage";
import Button from "@mui/material/Button";
import UnauthorizedMessage from "./Components/Dialogs/UnauthorizedMessage";
import { handleLogout } from './Helpers/Helpers';

function App() {

    const {
        selectedOption,
        handleOptionChange,
        showArea1,
        showArea2,
        showArea3,
        showArea4,
        showArea5,
    } = useDropdownLogic();

    const [scrolling, setScrolling] = useState(false);
    const scrollRef = useRef(null);
    const [ticketData, setTicketData] = useState({});
    const [continueTicketData, setContinueTicketData] = useState({});
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading
    const [openLoadingDialog, setOpenLoadingDialog] = useState(false); // Manage the loading dialog
    const [fileshareData, setFileshareData] = useState([]); // State variable to store retrieved files
    const [authRequest, setAuthRequest] = useState(false);
    const [loginRequest, setLoginRequest] = useState(false);
    const [profile, setProfile] = useState(null); // Initialize profile as null
    const [emailAddress, setEmailAddress] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [user_access_role, setUser_access_role] = useState('');
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [launchMode, setLaunchMode] = useState(false);

    // Define the setProfile function
    const setProfileData = (data) => {
        setProfile(data);
    };

    const openTicketDialogHandler = () => {
        setOpenTicketDialog(true);
    };

    // Function to handle closing the ticket dialog
    const closeTicketDialogHandler = () => {
        //localStorage.removeItem('ticketId');
        //setOpenTicketDialog(false);
    };

    // Function to continue with the ticket when "Yes" is clicked
    const continueWithTicket = () => {
        // Set isLoading to false when data is fetched
        setIsLoading(true);
        // Close the loading dialog
        setOpenLoadingDialog(true);
        console.log(localStorage.getItem('ticketId'));

        // Retrieve the ticketId from local storage and set it
        if (localStorage.getItem('ticketId')) {
            // Call the fetchBaseTicketData function
            fetchBaseTicketData(localStorage.getItem('ticketId'))
                .then(data => {
                    // Store the API response data in the state variable (renamed to ticketData)
                    setTicketData(data);

                    // Set the ticketData again after a short period of time
                    // because sometimes, the form is not loaded already
                    // and the state variable is not triggered
                    setTimeout(() => {
                        setTicketData(data);
                    }, 500);

                    // Set isLoading to false when data is fetched
                    setIsLoading(false);
                    // Close the loading dialog
                    setOpenLoadingDialog(false);
                    // Close the ticket dialog
                    closeTicketDialogHandler();
                })
                .catch(error => {
                    console.error('Zoho API error:', error);
                    // Set isLoading to false when there's an error
                    setIsLoading(false);
                    // Close the loading dialog
                    setOpenLoadingDialog(false);
                });
        } else {
            alert("We could not get your old ticket, please reopen the page.")
        }
        // Close the dialog
        closeTicketDialogHandler();
    };

    // Access the query parameters directly from window.location.search
    const queryParams = new URLSearchParams(window.location.search);

    let ticketId = queryParams.get('t');
    let devModeParameter = queryParams.get('dev');

    useEffect(() => {
        setLaunchMode(true);

        // Check if the ticketId exists in local storage
        const ticketIdCache = localStorage.getItem('ticketId');
        const requestTime = localStorage.getItem('request_time');

        // Check if requestTime is within the last 10 minutes
        if (requestTime) {
            const currentTime = new Date();
            const requestDate = new Date(requestTime);
            const timeDifference = (currentTime - requestDate) / (1000 * 60); // Time difference in minutes
            console.log(timeDifference);

            if (timeDifference <= 10) {
                if (ticketIdCache && !ticketId) {
                    // If a ticketId is found and it's within the last 10 minutes, open the dialog
                    //openTicketDialogHandler();
                    continueWithTicket();
                }
            } else {
                console.log("Clearing!")
                // Optional: clear the outdated data if you don't want to use it anymore
                localStorage.removeItem('ticketId');
                localStorage.removeItem('request_time');
            }
        }
    }, [ticketId]);

    // Cache Ticket ID for redirect
    if (ticketId) {
        localStorage.setItem('ticketId', ticketId);

        // Store the current time as request_time
        const currentTime = new Date().toISOString();
        localStorage.setItem('request_time', currentTime);
    }

    const handleLogoutInternal = () => {
        // Delete the "email" and "name" properties from local storage
        handleLogout();
        window.location.href = 'https://www.copadata.com/sso/logout';
    };

    const getEmailFromLocalStorage = () => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmailAddress(storedEmail);
        }
    };

    const getAccessRoleFromLocalStorage = () => {
        let accessRole = "";
        try {
            accessRole = localStorage.getItem('user_access_role');
        } catch {
            accessRole = "";
        }
        if (accessRole) {
            if (accessRole.includes("employee")) {
                setUser_access_role(accessRole);
                setAuthorized(true);
            }
        }
        if (!accessRole || accessRole === "") {
            window.location.href = "/login";
        }
    };

    const getAccessTokenFromLocalStorage = () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken && accessToken !== "") {
            setAccessToken(accessToken);
        } else {
            window.location.href = '/login';
        }
    };

    useEffect(() => {
        if (scrolling && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrolling(false);
        }
    }, [scrolling]);

    useEffect(() => {
        if (ticketId) {
            // Set isLoading to true when starting the API request
            setIsLoading(true);
            setOpenLoadingDialog(true);

            // Call the fetchBaseTicketData function
            fetchBaseTicketData(ticketId)
                .then(data => {
                    console.log(data);
                    // Store the API response data in the state variable (renamed to ticketData)
                    setTicketData(data);
                    // Set isLoading to false when data is fetched
                    setIsLoading(false);
                    // Close the loading dialog
                    setOpenLoadingDialog(false);
                })
                .catch(error => {
                    console.error('zoho API error:', error);
                    // Set isLoading to false when there's an error
                    setIsLoading(false);
                    // Close the loading dialog
                    setOpenLoadingDialog(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [ticketId]);

    useEffect(() => {
        if (!window.location.pathname.includes("/auth") && !window.location.pathname.includes("/login")) {
            // Fetch and handle authorization data
            const fetchAuthorizationData = () => {
                if (!emailAddress) {
                    getEmailFromLocalStorage();
                }
                if (!accessToken) {
                    getAccessTokenFromLocalStorage();
                }
                if (!user_access_role) {
                    getAccessRoleFromLocalStorage();
                }
            };
            fetchAuthorizationData(); // Initial fetch
        }
    }, [emailAddress, accessToken, user_access_role]);

    // Get the fileshare data after receiving a ticket short id
    useEffect(() => {
        if (ticketData) {
            if (ticketData.ticketNumber) {
                // Call the fetchFileshareData function to retrieve fileshare data
                fetchFileshareData(ticketData.ticketNumber, accessToken)
                    .then(data => {
                        console.log(data);
                        // Store the retrieved files in the state variable
                        setFileshareData(data);
                    })
                    .catch(error => {
                        console.error('Fileshare API error:', error);
                    });
            }
        }
    }, [ticketData, accessToken]);

    return (
        <Provider store={store}>
            <div className="App">
                <AppBar position="static" className="title-bar">
                    <Toolbar>
                        <img
                            src="img/logo-smooool.png"
                            height="40"
                            style={{ margin: 5 }}
                            alt="Logo"
                            className="logo"
                        />

                        <div style={{ marginLeft: 'auto', marginRight: 20, textAlign: 'right' }}>
                            <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {localStorage.getItem('name')}
                            </div>
                            <div style={{ fontSize: '0.8rem' }}>
                                {localStorage.getItem('email')}
                            </div>
                        </div>
                        <Button style={{ color: 'white', backgroundColor: '#202D4F' }}
                                onClick={handleLogoutInternal}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                {window.location.pathname.includes("/auth") && (
                    (() => {
                        return <AuthPage />;
                    })()
                )}
                {window.location.pathname.includes("/login") && (
                    (() => {
                        return <LoginPage />;
                    })()
                )}
                {!window.location.pathname.includes("/auth") && !window.location.pathname.includes("/login") && !user_access_role.includes('employee') && (
                    (() => {
                        return <UnauthorizedMessage />;
                    })()
                )}
                {!window.location.pathname.includes("/auth") && !window.location.pathname.includes("/login") && user_access_role.includes('employee') && (
                    (
                        <HomeContainer
                            selectedOption={selectedOption}
                            handleOptionChange={handleOptionChange}
                            scrollRef={scrollRef}
                            isLoading={isLoading}
                            showArea1={showArea1}
                            showArea2={showArea2}
                            showArea3={showArea3}
                            showArea4={showArea4}
                            showArea5={showArea5}
                            ticketData={ticketData}
                            fileshareData={fileshareData}
                            openLoadingDialog={openLoadingDialog}
                            profile={profile}
                            devMode={launchMode}
                        />
                    )
                )}
                <Footer />

                <Dialog open={openTicketDialog} onClose={closeTicketDialogHandler}>
                    <DialogTitle>Continue with Assistance Request?</DialogTitle>
                    <DialogContent>
                        <Typography style={{ marginBottom: 10 }}>
                            We found an old assistance request that was not completed. This can be caused if you
                            close the Browser or logged in through OneLogin recently.
                        </Typography>
                        <Card
                            style={{
                                border: '1px solid #C5CAE9',
                                borderRadius: '10px',
                                padding: '10px',
                            }}
                        >
                            <Typography>
                                Do you want to continue on the ticket with ID <b>{localStorage.getItem("ticketId")}</b>?
                            </Typography>
                            <a
                                href={`https://selfservice.copadata.com/agent/copadata/copa-data-1/tickets/details/${localStorage.getItem("ticketId")}`}
                                target="_blank" // This opens the link in a new tab
                                rel="noopener noreferrer"
                            >
                                <Button style={{ backgroundColor: "#C5CAE9", color: "black" }}>⟶ Open Ticket in Zoho</Button>
                            </a>
                        </Card>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                            <Button variant="contained" style={{ backgroundColor: "#B71C1C", marginRight: 10 }} onClick={closeTicketDialogHandler}>
                                Discard
                            </Button>
                            <Button variant="contained" style={{ backgroundColor: "#172038" }} onClick={continueWithTicket}>
                                Continue
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </Provider>
    );
}

export default App;
