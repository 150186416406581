import {removeUnwantedElements} from "./Helpers";

export function simulateLoading(progressBar, progressText) {
    let loadingPercentage = 0;

    if (progressText) {
        progressText.style.display = 'none';
    }

    const updateProgress = () => {
        loadingPercentage = (loadingPercentage + 1) % 100;

        // Update the progress bar and text
        progressBar.value = loadingPercentage;

        // Schedule the next update (adjust the timeout as needed)
        setTimeout(updateProgress, 50);
    };

    // Start simulating loading
    updateProgress();
}

export function processImageData(formData, requestData) {
    //todo: check if these are all
    const fieldsWithImages = ['done_steps', 'reproduce_steps', 'what_do_you_want', 'description', 'additional_information'];

    for (const field of fieldsWithImages) {

        if(!requestData[field]){
            continue;
        }

        let fieldContent = requestData[field];

        formData.images.forEach(({ uniqueName, blobName }) => {
            fieldContent = fieldContent.replace(blobName, uniqueName);
        });

        fieldContent = removeUnwantedElements(fieldContent);

        // Update formData with the modified field content
        requestData[field] = fieldContent;
    }

    for (const storedAttachment of formData.images) {

        const { uniqueName, fileContent, blobName } = storedAttachment;

        // Extract MIME type using regular expression
        const mimeTypeMatch = fileContent.match(/^data:(.*?);base64/);
        const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : 'application/octet-stream';

        requestData.attachments.push({
            data: fileContent.split(',')[1], // Extract base64 content
            name: uniqueName, // Use blobName as the filename for simplicity
            mimeType: mimeType, // Adjust the MIME type as needed
        });
    }

    console.log("Attachments:")
    console.log(requestData.attachments);
}


export async function uploadFilesToShare(files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, ticketNumber, createdTicketNumber, setOpenErrorUploadDialog) {
    const formDataObj = new FormData();

    files.forEach((file, index) => {
        formDataObj.append('Files', file);
    });

    formDataObj.append('Auth', 'vaUdbyTD3p6rl7nns3tt');
    if(ticketNumber){
        // Base Ticket exists
        formDataObj.append('TicketId', ticketNumber);
    } else {
        // No Base ticket, append new ticket ID
        formDataObj.append('TicketId', createdTicketNumber);
    }

    const progressBar = document.getElementById('progress-bar');
    const progressText = document.getElementById('progress-text');

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            console.log(progress);
            progressBar.value = Math.round(progress);
            progressText.innerText = `${Math.round(progress)} %`;
        }
    });

    xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
            setOpenProgressBar(false);
            setOpenErrorDialog(false);
            setOpenErrorUploadDialog(false);
            setOpenSuccessDialog(true);
        } else {
            console.error(`File Upload Failed with status ${xhr.status}`);
            localStorage.setItem("error", `File Upload failed with error ${xhr.responseText}`);
            setOpenProgressBar(false);
            setOpenErrorDialog(false);
            setOpenErrorUploadDialog(true);
            setOpenSuccessDialog(false);
        }
    });

    xhr.addEventListener('error', (error) => {
        console.error('Error:', error.message);
        localStorage.setItem("error", `File Upload failed with error ${error.message}`);
        setOpenProgressBar(false);
        setOpenErrorDialog(false);
        setOpenErrorUploadDialog(true);
        setOpenSuccessDialog(false);
    });

    xhr.open('POST', 'https://fileapi.copatools.com/api/Share/upload', true);
    xhr.send(formDataObj);
}