// dropdownLogic.js

import { useState } from 'react';

export function useDropdownLogic() {
    const [selectedOption, setSelectedOption] = useState('');
    const [showArea1, setShowArea1] = useState(false);
    const [showArea2, setShowArea2] = useState(false);
    const [showArea3, setShowArea3] = useState(false);
    const [showArea4, setShowArea4] = useState(false);
    const [showArea5, setShowArea5] = useState(false);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        // Depending on the selected option, set the corresponding state to true
        if (selectedValue === 'area1') {
            setShowArea1(true);
            setShowArea2(false);
            setShowArea3(false);
            setShowArea4(false);
            setShowArea5(false);
        } else if (selectedValue === 'area2') {
            setShowArea1(false);
            setShowArea2(true);
            setShowArea3(false);
            setShowArea4(false);
            setShowArea5(false);
        } else if (selectedValue === 'area3') {
            setShowArea1(false);
            setShowArea2(false);
            setShowArea3(true);
            setShowArea4(false);
            setShowArea5(false);
        }
        else if (selectedValue === 'area4') {
            setShowArea1(false);
            setShowArea2(false);
            setShowArea3(false);
            setShowArea4(true);
            setShowArea5(false);
        }
        else if (selectedValue === 'area5') {
            setShowArea1(false);
            setShowArea2(false);
            setShowArea3(false);
            setShowArea4(false);
            setShowArea5(true);
        }


    };

    return {
        selectedOption,
        handleOptionChange,
        showArea1,
        showArea2,
        showArea3,
        showArea4,
        showArea5,
    };
}
