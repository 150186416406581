import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, InputAdornment, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

function PrioritySelector({ priority, handlePriorityChange, responseTime, setResponseTime}) {
    return (
        <>
            <Grid item xs={10} sm={3}>
                <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                    <InputLabel htmlFor="priority" required>
                        Priority
                    </InputLabel>
                    <Select
                        labelId="priority-label"
                        id="priority"
                        value={priority}
                        onChange={(event) => {
                            handlePriorityChange(event); // Update the priority state
                        }}
                        label="Priority"
                        startAdornment={(
                            <InputAdornment position="start">
                                {priority === 'Critical' ? <WarningAmberIcon /> : <PriorityHighIcon />}
                            </InputAdornment>
                        )}
                    >
                        <MenuItem value="Low">Standard</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Critical">Critical</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid style={{ marginLeft: 10 }} item xs={11} sm={3}>
                <Typography><b>Maximum Response Time</b></Typography>
                <Typography>{responseTime}</Typography>
            </Grid>

            {priority.includes('Critical') && (
                <Grid item xs={11}>
                    <Box bgcolor="#ffb74d" p={1} borderRadius={'10px'} alignItems="center">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <img src="img/siren.gif" alt="Siren" height="35px"/>
                            <Typography style={{
                                fontFamily: 'Arimo',
                                fontWeight: 'bold',
                                paddingLeft: 20,
                                fontSize: 25,
                                paddingTop: 15
                            }} variant="h5">
                                Be careful!
                            </Typography>
                        </div>
                        <center>
                            <Typography
                                style={{paddingLeft: 20, paddingRight: 100, paddingBottom: 15, fontFamily: 'Arimo'}}>
                                <b>Critical assistant requests</b> will trigger notifications to multiple individuals at
                                CD-HQ, including immediate interruption of their current tasks. <b>Please use this
                                sparingly, as it's reserved for really time critical cases with highly important
                                customers.</b>
                            </Typography>
                        </center>
                    </Box>

                </Grid>
            )}

            {priority.includes('Critical') || priority.includes('High') ? (
                <Grid item xs={12} style={{marginRight: 20, marginLeft: 20}}>
                    <Container>
                        <Typography style={{fontFamily: 'Arimo'}}>
                            <b>The following criteria apply to this priority:</b>
                        </Typography>
                        <Typography style={{fontFamily: 'Arimo', marginRight: 100}}>
                            <ul>
                                <li style={{marginBottom: 10}}>
                                    The concerned system is non-functional (Service Engine) or engineering processes are
                                    completely interrupted (Engineering Studio) with <b>no acceptable workaround</b>.
                                </li>
                                <li style={{marginBottom: 10}}>
                                    Full <b>commitment</b> of requester and customer contact, including
                                    immediate <b>availability</b> for further troubleshooting.
                                </li>
                                <li style={{marginBottom: 10}}>
                                    The license of the affected system is under an <b>SLA</b>.
                                </li>
                                <li style={{marginBottom: 10}}>
                                    Issue <b>unrelated to API</b> usage or custom code.
                                </li>
                            </ul>
                        </Typography>

                    </Container>
                </Grid>
            ) : null}



        </>

    );
}

export default PrioritySelector;
