import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

const SuccessDialog = ({ open, onClose }) => {

    const handleClick = () => {
        // Open the dynamic URL in a new tab

        //https://selfservice.copadata.com/portal/en/ticket/[ticket_id]
        window.open(`https://selfservice.copadata.com/portal/en/ticket/${localStorage.getItem("createdTicket")}`, '_blank');
    };

    const handleClose = () => {
        // Clean File cache
        localStorage.removeItem('localFileData');

        // Open the dynamic URL in a new tab
        window.location.href = "/";
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div style={{ display: 'flex' }}>
                <img
                    src="img/duck.jpg"
                    alt="Success Icon"
                    style={{ marginRight: '10px', width: '200px'}}
                />
                <DialogContent>
                    <Typography style={{ fontFamily: 'Arimo' }} variant="h6">
                        Success!
                    </Typography>

                    <Typography>
                        A new ticket has been created for your request and will be promptly assigned to the most suitable expert.
                        Our team will review your inquiry and provide you with the necessary assistance as soon as possible.
                    </Typography>

                    <Button onClick={handleClick} style={{ backgroundColor: '#202D4F', color: "white", marginTop: 10 }}>
                        Open Request in zoho
                    </Button>

                    <Button onClick={handleClose} style={{ backgroundColor: '#8C0000', color: "white", marginLeft:10, marginTop: 10 }}>
                        Close
                    </Button>

                </DialogContent>
            </div>
        </Dialog>

    );
};

export default SuccessDialog;
