import React, { useState, useEffect, useRef } from 'react';
import { checkFileChanges, validateSerial, getResponseTime } from '../../Helpers/Helpers';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BuildIcon from '@mui/icons-material/Build';
import SubjectIcon from '@mui/icons-material/Subject';
import {CardActions, Divider, Grid, IconButton, Paper} from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TrixEditor from "../TrixEditor";
import Button from "@mui/material/Button";
import FileUpload from 'react-material-file-upload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

import {
    process_license_request as handleLicenseRequest
} from '../../Helpers/RequestProcessors/LicenseRequestProcessing';
import ProgressBarDialog from "../Dialogs/ProgressBarDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import ErrorDialog from "../Dialogs/ErrorDialog";
import VersionSelector from "../Statics/VersionSelector";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TooltipDialog from "../Dialogs/ToolTipDialog";
import Tooltip from "@mui/material/Tooltip";
import UploadErrorDialog from "../Dialogs/UploadErrorDialog";

function LicenseRequestForm({ ticketData, fileshareData, profile }) {
    // Initialize the 'subject' state with the value from 'ticketData.subject' if it exists, or an empty string otherwise
    const [subject, setSubject] = useState(ticketData.subject || '');
    const [productVersion, setProductVersion] = useState(ticketData.productId || '57961000009317983');
    const [serialNumber, setSerialNumber] = useState(ticketData.serialNumber || '');

    const [article_number, set_article_number] = useState('');
    const [article_base, set_article_base] = useState('');

    const [tooltip_text, set_tooltip_text] = useState('');
    const [tooltip_image, set_tooltip_image] = useState('');

    const [serialNumberError, setSerialNumberError] = useState(false);
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);
    const [prevFiles, setPrevFiles] = useState([]);
    const [openProgressBar, setOpenProgressBar] = useState(false);

    // Success Dialog open / close
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openErrorUploadDialog, setOpenErrorUploadDialog] = useState(false);
    const [images, setImages] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [progress, setProgress] = useState(0); // Add progress state
    const [subjectError, setSubjectError] = useState(false);
    const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);
    const [license_problem_kind, setLicenseProblemKind] = useState(''); // State variable for problem type

    // Add a ref for the hidden input
    const imagesInputRef = useRef(null);
    const descriptionRef = useRef(null);

    const handleLicenseProblemKindChange = (event) => {
        setLicenseProblemKind(event.target.value);
    };

    const handleFileChange = (newFiles) => {
        checkFileChanges(newFiles, prevFiles, setFiles, setPrevFiles);
    };

    const handleCreateTRClick = () => {
        // Redirect to the specified URL when the button is clicked
        window.location.reload();
    };

    const handleButtonClick = async () => {

        // Default: Handle Artikelstamm Issue
        // Mandatory fields: Subject, Artikelnummer, Artikelstamm, Description
        // Optional: License Number + Files
        let requiredFields = [
            { value: subject, name: 'Subject', setError: setSubjectError },
            { value: description, name: 'Description'},
            { value: article_base, name: 'Article Base / Artikelstamm'},
            { value: article_number, name: 'Article Number'},
        ];

        if(license_problem_kind == "Issue with CRM License Update"){

            // Handle CRM issue
            // Mandatory: Subject, Description
            // Optional: Seriennummer + Files

            requiredFields = [
                { value: subject, name: 'Subject', setError: setSubjectError },
                { value: description, name: 'Description'},
            ];
        }

        // Create an array to store the missing fields
        const missingFields = [];

        requiredFields.forEach(field => {
            if (!field.value) {
                missingFields.push(field.name);
                if (field.setError) {
                    field.setError(true);
                }
            }
        });

        if (missingFields.length > 0) {
            // Log the missing fields to the console
            setOpenMissingFieldsDialog(true);
            alert('Please fill out the following fields:\n' + missingFields.join('\n'));
            console.log('Missing Fields: ', missingFields.join(', '));
            return;
        }

        // Show the progress bar dialog before starting the upload
        setOpenProgressBar(true);

        // Wait for the progress bar dialog to be fully opened
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second as an example

        // Handle file upload and progress updates here
        await handleLicenseRequest(
            {
                article_number,
                article_base,
                license_problem_kind,
                productVersion,
                serialNumber,
                description,
                subject,
                ticketData,
                images,  // Include images in the form data
            },
            files,
            setOpenProgressBar,
            setOpenSuccessDialog,
            setOpenErrorDialog,
            setOpenErrorUploadDialog,
        );
    };

    const handleClose = () => {
        // Commented out so users can not close the completed form
        //setOpenSuccessDialog(false);
    };

    const handleToolTipClose = () => {
        setTooltipOpen(false); // Update the variable when the dialog is closed
    };

    const handleToolTipOpen  = (text, image) => {
        set_tooltip_text(text); // Update the text state variable
        set_tooltip_image(image); // Update the image state variable
        setTooltipOpen(true); // Update the variable when the dialog is closed
    };

    const handleCloseError = () => {
        setOpenErrorDialog(false);
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };

    const handleArticleNumberChange = (event) => {
        set_article_number(event.target.value);
    };

    const handleArticleBaseChange = (event) => {
        set_article_base(event.target.value);
    };

    const handleProductVersionChange = (event) => {
        setProductVersion(event.target.value);
    };

    // Validate Serial Number
    const handleSerialNumberChange = (event) => {
        const inputSerialNumber = event.target.value;

        // Call the validateSerial function
        validateSerial(inputSerialNumber, setSerialNumber, setSerialNumberError);
    };

    // Gets executed once at startup
    useEffect(() => {
        localStorage.removeItem('localFileData');
    }, []);

    // Convert the 'images' state to a JSON string
    const imagesJson = JSON.stringify(images);

    useEffect(() => {
        if (imagesInputRef.current) {
            // Update the value of the ref
            imagesInputRef.current.value = imagesJson;
        }
    }, [imagesJson]); // Run this effect whenever 'imagesJson' changes

    return (

        <form onSubmit={handleButtonClick}>

            <ProgressBarDialog
                open={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
                progress={progress}
                setProgress={setProgress} // Pass the setProgress function here
            />

            <Card style={{ marginBottom:100 }}>
                <center>
                    <Typography style={{ marginBottom: 20, marginTop: 30, fontFamily: 'Arimo', fontWeight: 'bold' }} variant="h5">
                        License Request
                    </Typography>
                    <Typography>Get assistance from our licensing team about issues with Sunrise CRM and license activation.</Typography>
                </center>

                {/* Hidden input to cache local images */}
                <input type="hidden" name="images" ref={imagesInputRef}/>

                {/* Insert Selector which allows to set the kind of problem */}
                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50, marginTop:20, marginBottom: 20 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            Which kind of License issue do you have?
                        </Typography>
                    </div>


                    {/* Problem Type Selector */}
                    <FormControl fullWidth variant="outlined" style={{ margin: 30, marginTop:30}}>
                        <InputLabel id="problem-type-label">Problem Type</InputLabel>
                        <Select
                            labelId="problem-type-label"
                            id="problem-type-select"
                            value={license_problem_kind}
                            onChange={handleLicenseProblemKindChange}
                            label="Problem Type"
                            style={{paddingRight:20, marginRight:50}}
                        >
                            <MenuItem value="">
                                <em>Please select</em>
                            </MenuItem>
                            <MenuItem value="Issue with License Article Base">Article Catalog (Artikelstamm) of a product is configured incorrectly</MenuItem>
                            <MenuItem value="Issue with CRM License Update">Problems while doing a License Update in Sunrise CRM</MenuItem>
                            <MenuItem value="technical-request">General Problems with the Activation of a License</MenuItem>
                        </Select>
                    </FormControl>

                </div>

                {(license_problem_kind !== 'technical-request') && (license_problem_kind !== '') && (
                    <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                        <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                            <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                                Basic Information about your License Request
                            </Typography>
                        </div>
                        <Grid style={{ margin: 20 }} container spacing={2}>
                            <Grid item xs={10} sm={11}>
                                <TextField
                                    label="Subject *"
                                    variant="outlined"
                                    fullWidth
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SubjectIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={10} sm={5}>
                                <VersionSelector
                                    productVersion={productVersion}
                                    handleProductVersionChange={handleProductVersionChange}
                                />
                            </Grid>

                            <Grid item xs={10} sm={6}>
                                <TextField
                                    label="Serial Number"
                                    variant="outlined"
                                    fullWidth
                                    value={serialNumber}
                                    onChange={handleSerialNumberChange}
                                    error={serialNumberError} // Add error state
                                    helperText={serialNumberError ? 'Expected Format: C00XX-XXXXX-XXXXX-12345-12345' : ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </div>
                )}


                {license_problem_kind === 'Issue with License Article Base' && (
                    <div>
                        <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                            <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                                <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                                    Request Details
                                </Typography>
                            </div>

                            <Grid style={{ margin: 20 }} container spacing={2}>

                                <TooltipDialog
                                    open={tooltipOpen} // Ensure the dialog is always open
                                    onClose={handleToolTipClose} // You can leave the onClose handler empty if you don't need it
                                    text={tooltip_text}
                                    imageSrc={tooltip_image}
                                />

                                <Grid item xs={10} sm={11}>
                                    <TextField
                                        label="Article Number *"
                                        variant="outlined"
                                        fullWidth
                                        value={article_number}
                                        onChange={handleArticleNumberChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Inventory2Icon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: ( // Add the tooltip with the screenshot here
                                                <InputAdornment position="end">

                                                        <IconButton onClick={() => handleToolTipOpen("The article number can be found in Sunrise CRM and looks like this for example:", "img/tooltips/tooltip_article_number.png")}>
                                                            <HelpOutlineIcon />
                                                        </IconButton>

                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid style={{ margin: 20 }} container spacing={2}>
                                <Grid item xs={10} sm={11}>
                                    <TextField
                                        label="Article Catalog / Artikelstamm *"
                                        variant="outlined"
                                        fullWidth
                                        value={article_base}
                                        onChange={handleArticleBaseChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountTreeIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: ( // Add the tooltip with the screenshot here
                                                <InputAdornment position="end">

                                                    <IconButton onClick={() => handleToolTipOpen("The article base / Artikelstamm can be found in Sunrise CRM. For example: zenon 14 > zenon Supervisor > Service Engine", "img/tooltips/tooltip_article_base.png")}>
                                                        <HelpOutlineIcon />
                                                    </IconButton>

                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>


                            {/* Encapsulate each section with the TrixEditor component */}
                            <TrixEditor
                                inputId="customer_description_input"
                                label="Description"
                                description="Please let us know what's the current situation on the issue, be as detailed as possible to speed up the process!"
                                onChange={handleDescriptionChange}
                                required="true"
                                setImages={setImages}
                                images={images}
                                ref={descriptionRef}
                            />
                        </div>
                    </div>
                )}

                {license_problem_kind === 'Issue with CRM License Update' && (
                    <div>
                        <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50 }}>
                            <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                                <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                                    Details about your CRM License Issue
                                </Typography>
                            </div>


                            {/* Encapsulate each section with the TrixEditor component */}
                            <TrixEditor
                                inputId="description_input"
                                label="Description"
                                description="Please let us know what's the current situation on the issue, be as detailed as possible to speed up the process!"
                                onChange={handleDescriptionChange}
                                required="true"
                                setImages={setImages}
                                images={images}
                                ref={descriptionRef}
                            />
                        </div>
                    </div>
                )}

                {(license_problem_kind !== 'technical-request') && (license_problem_kind !== '') && (
                    <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                        <div style={{
                            background: '#202D4F',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            padding: '10px',
                            textAlign: 'center'
                        }}>
                            <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                Files & Logs
                            </Typography>
                        </div>

                        <div className="file-upload-container" style={{margin: 20}}>
                            <Typography style={{fontFamily: 'Arimo', marginBottom:20}} >Please provide all files that already were captured about this issue.</Typography>

                            {fileshareData.length > 0 && (  // Conditionally render the specific Box
                                <Box bgcolor="#357a38" p={5} m={2} borderRadius={'10px'} alignItems="center">
                                    <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                        Yey! Some files already exist for this request!
                                    </Typography>
                                    <Typography style={{color: 'white'}}>
                                        The following files are already present in the existing Fileshare
                                        for the Ticket and do not need to be uploaded again. We will attach them to your
                                        request automatically:
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {fileshareData.map((file, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{color: '#fff'}}>{file}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}

                            <FileUpload
                                value={files}
                                maxSize={524288000}
                                onChange={handleFileChange}

                                buttonText="Select Files"
                                title="Maximum Size: 500 MB / File. Drag 'n' drop some files here, or click to select files"/>

                            <Box bgcolor="#f57c00" p={5} mt={2} borderRadius={'10px'} alignItems="center">
                                <Typography style={{color: 'white'}}><b>Info: </b> If you raise this request from an
                                    existing ticket, the files will be uploaded to the existing file share of the
                                    ticket: All these files will be visible to the customer!
                                    For ARs without a linked ticket, a new File Share will be created.
                                    Please be aware that it can take some time that you can see the files in the file
                                    share.
                                </Typography>
                            </Box>
                        </div>
                    </div>


                )}

                {(license_problem_kind === 'technical-request') && (
                    <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                        <div style={{
                            background: '#202D4F',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            padding: '10px',
                            textAlign: 'center'
                        }}>
                            <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                We are sorry!
                            </Typography>
                        </div>

                        <center style={{marginTop:20}}>


                            <img width='150px' style={{marginRight:-50, marginBottom:80}} alt='Speech Bubble stating license broken' src='img/license_kaputti_speech.png'/>
                            <img width='200px' alt='A raging man because his license is not working :(' src='img/man_raging.gif'/>

                            <div style={{ margin: 50, paddingRight:20, paddingLeft:20, marginTop: -30, marginBottom: 10, fontFamily: 'Arimo' }}>
                                <p>
                                    If you or a customer are experiencing technical issues with license activation, returns, or any other general license-related problem, please create a Technical Support Request from the existing ticket.
                                </p>
                                <p>
                                     Use this request type specifically for issues related to the licensing infrastructure. This includes problems in the CRM License Administration or any errors in the article base that need to be fixed or supported by our Licensing Team.
                                </p>
                            </div>

                            <Button onClick={handleCreateTRClick} style={{ alignSelf: 'flex-end', marginBottom: 10 }} variant="outlined" color="primary">
                                Create Technical Request
                            </Button>

                        </center>

                    </div>
                )}

                {(license_problem_kind !== 'technical-request') && (license_problem_kind !== '') && (
                    <CardActions>

                        <div style={{ marginLeft: 'auto', marginRight:45, marginTop:-30, marginBottom:20 }}>
                            <Button
                                style={{ backgroundColor: '#202D4F' }}
                                variant="contained"
                                onClick={handleButtonClick}
                            >
                                Request Assistance
                            </Button>
                        </div>

                    </CardActions>
                )}



                <SuccessDialog open={openSuccessDialog} onClose={handleClose} />
                <UploadErrorDialog open={openErrorUploadDialog} onClose={handleClose}/>
                <ErrorDialog open={openErrorDialog} onClose={handleCloseError}/>

            </Card>
        </form>
    );
}

export default LicenseRequestForm;
