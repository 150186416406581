import queryString from 'query-string';

// Static oidc params for a single provider
const authority = "https://copadata.onelogin.com/oidc/2";
const defaultClientId = "45a82980-4e39-013c-2728-1a21ff8b73c438555";
const defaultRedirectUri = "https://assistance.copatools.com/auth";
const response_type = "id_token token";
const scope = "openid profile params";

// Check if the current URL contains "localhost"
const isLocalhost = window.location.href.includes("localhost");

// Set parameters based on the environment
const client_id = isLocalhost ? "8fddb950-9cc9-013c-fcd0-3e429a16045c38555" : defaultClientId;
const redirect_uri = isLocalhost ? "http://localhost:3100/auth" : defaultRedirectUri;

export const beginAuth = ({ state, nonce }) => {
    // Generate authentication URL
    const params = queryString.stringify({
        client_id,
        redirect_uri,
        response_type,
        scope,
        state,
        nonce
    });
    const authUrl = `${authority}/auth?${params}`;

    // Attempt login by navigating to authUrl
    window.location.assign(authUrl);
};
