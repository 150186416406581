import React, {useEffect, useRef, useState} from 'react';
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import PSRequestForm from "./Forms/PSRequestForm";
import EducationalRequestForm from "./Forms/EducationalRequestForm";
import TechnicalRequestForm from "./Forms/TechnicalRequestForm";
import MenuItem from "@mui/material/MenuItem";
import {CardMedia, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import LicenseRequestForm from "./Forms/LicenseRequestForm";
import VMRequestForm from "./Forms/VMRequestForm";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const HomeContainer = ({
                           selectedOption,
                           handleOptionChange,
                           scrollRef,
                           isLoading,
                           showArea1,
                           showArea2,
                           showArea3,
                           showArea4,
                           showArea5,
                           ticketData,
                           fileshareData,
                           openLoadingDialog,
                           profile,
                           devMode,
                       }) => {

    const scrollToRef = (ref) => {
        setScrolling(true);
        scrollRef.current = ref;
    };
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        if (scrolling && scrollRef.current) {
            scrollRef.current.scrollIntoView({behavior: 'smooth'});
            setScrolling(false);
        }
    }, [scrolling]);


    return (

        <Container>
            {ticketData?.subject && (
                <Card style={{marginTop: 50, marginBottom: 50, backgroundColor:'#172038'}}>
                    <CardContent>

                        <Typography style={{fontFamily: 'Arimo', fontSize:20, color:'#fff'}}>(ℹ️) Base Ticket Detected</Typography>

                        <Typography variant="body1" style={{fontFamily: 'Arimo', marginBottom: 5, color:'#fff'}}>
                            You are creating the Assistance Request based on the following ticket:
                        </Typography>

                        <Typography style={{fontFamily: 'Arimo', color:'#fff'}}>
                            <strong>Subject:</strong> {ticketData.subject}
                        </Typography>

                        <Typography style={{fontFamily: 'Arimo', color:'#fff', marginBottom: 10,}}>
                            <strong>Number:</strong> #{ticketData.ticketNumber}
                        </Typography>

                        <Button
                            style={{ color: 'white', backgroundColor: '#202D4F', fontFamily: 'Arimo', marginBottom:-5 }}
                            onClick={() => {
                                localStorage.removeItem('ticketId'); // Remove the ticketId from localStorage
                                // Reload the page with the new URL
                                window.location.href = window.location.origin;
                            }}
                        >
                            Dismiss and continue without ticket linkage
                        </Button>

                    </CardContent>
                </Card>
            )}
            <Card style={{marginTop: 50, marginBottom: 50}}>
                <CardMedia
                    component="img"
                    height="140"
                    image="img/nikko-macaspac-6SNbWyFwuhk-unsplash.jpg"
                    alt="stock-image"
                />
                <CardContent>
                    <center>
                        <Typography gutterBottom variant="h5" component="div" style={{fontFamily: 'Arimo'}}>
                            Welcome to the Assistance Request Workflow
                        </Typography>
                        <Typography>
                            We've got your back, so don't be shy — request assistance for your ticket or any help you
                            need.
                        </Typography>
                        <Typography>
                            Please let us know, what kind of assistance you require.
                        </Typography>


                        <FormControl variant="outlined" style={{width: '300px', margin: 20}}>
                            <InputLabel id="select-label">Type of Request</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={selectedOption}
                                label="Select an Option"
                                onChange={(e) => {
                                    handleOptionChange(e);
                                    scrollToRef(scrollRef.current);
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="area1">Technical Request</MenuItem>
                                <MenuItem value="area2" disabled={!devMode}>Professional Services Request</MenuItem>
                                <MenuItem value="area3" disabled={!devMode}>License Request</MenuItem>
                                <MenuItem value="area4" disabled={!devMode}>Education Services Request</MenuItem>
                                <MenuItem value="area5" disabled={!devMode}>VM Administration Request</MenuItem>
                            </Select>
                        </FormControl>
                    </center>
                </CardContent>
            </Card>

            {/* Conditionally render loading indicator or the form */}
            {isLoading ? null : (
                <>
                    {showArea1 && (
                        <div ref={scrollRef} className={`fade-in ${showArea1 ? 'show' : ''}`}>
                            <TechnicalRequestForm ticketData={ticketData} fileshareData={fileshareData}
                                                  profile={profile}/>
                        </div>
                    )}
                    {showArea2 && (
                        <div ref={scrollRef} className={`fade-in ${showArea2 ? 'show' : ''}`}>
                            <PSRequestForm ticketData={ticketData} fileshareData={fileshareData} profile={profile}/>
                        </div>
                    )}
                    {showArea3 && (
                        <div ref={scrollRef} className={`fade-in ${showArea3 ? 'show' : ''}`}>
                            <LicenseRequestForm ticketData={ticketData} fileshareData={fileshareData}
                                                profile={profile}/>
                        </div>
                    )}

                    {showArea4 && (
                        <div ref={scrollRef} className={`fade-in ${showArea4 ? 'show' : ''}`}>
                            <EducationalRequestForm ticketData={ticketData} fileshareData={fileshareData}
                                                    profile={profile}/>
                        </div>
                    )}

                    {showArea5 && (
                        <div ref={scrollRef} className={`fade-in ${showArea5 ? 'show' : ''}`}>
                            <VMRequestForm ticketData={ticketData} fileshareData={fileshareData} profile={profile}/>
                        </div>
                    )}
                </>
            )}

            {/* Loading Dialog */}
            <Dialog open={openLoadingDialog}>
                <center>
                    <Typography gutterBottom variant="h5" component="div" style={{fontFamily: 'Arimo', margin: 20}}>
                        Loading Request Data
                    </Typography>
                    <Typography>
                        Please bear with us while we load the existing data from the ticket...
                    </Typography>
                </center>
                <DialogContent style={{margin: 0, padding: 0}}>
                    <img width="100%" src="img/animated-cat.gif" style={{margin: 0, padding: 0}}
                         alt="Loading Animation..."/>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default HomeContainer;
