import React from "react";
import { connect } from "react-redux";


import * as oidcApi from "../../API/oidcApi";
import { generateStateAndNonce } from "../../actions/tokenActions";

const LoginPage = ({ state, nonce, generateParams }) => {
    if (state === null || nonce === null) {
        generateParams();
    } else {
        oidcApi.beginAuth({ state, nonce });
    }

    return (
        <p style={{color:'#fff'}}>Logging in...</p>
    );
};

const mapStateToProps = state => ({
    state: state.token.state,
    nonce: state.token.nonce
});

const mapDispatchToProps = dispatch => ({
    generateParams: () => dispatch(generateStateAndNonce())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
