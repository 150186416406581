export const handleLogout = () => {
    // Delete the "email" and "name" properties from local storage
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('user_access_role');
    localStorage.removeItem('localFileData');
};

export function removeUnwantedElements(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Remove progress element
    const progressElements = doc.querySelectorAll('progress');
    progressElements.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    // Move images out of the figure tags
    const figureElements = doc.querySelectorAll('figure');
    figureElements.forEach((figure) => {
        // Extract the content of the figure and move it before the figure
        const figureContent = figure.innerHTML;
        figure.insertAdjacentHTML('beforebegin', figureContent);

        // Remove the original figure
        figure.parentNode.removeChild(figure);
    });

    //figcaption
    const captionElements = doc.querySelectorAll('figcaption');
    captionElements.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    // Remove attachment__toolbar
    const toolbarElements = doc.querySelectorAll('.attachment__toolbar');
    toolbarElements.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    // Remove attachment__metadata-container
    const metadataElements = doc.querySelectorAll('.attachment__metadata-container');
    metadataElements.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    // Remove specified attributes from img and figure elements and resize images because of zoho limitations
    const imgElements = doc.querySelectorAll('img');
    imgElements.forEach((element) => {
        element.removeAttribute('data-trix-store-key');
        element.removeAttribute('data-trix-mutable');
        element.removeAttribute('data-trix-serialized-attributes');

        const maxWidth = 700;
        const originalWidth = parseInt(element.getAttribute('width'), 10);
        const originalHeight = parseInt(element.getAttribute('height'), 10);

        // Check if width is > 700 (zoho renders weird in that case) -> Limit to 700
        if (originalWidth > maxWidth) {
            const aspectRatio = originalWidth / originalHeight;
            const newWidth = maxWidth;
            const newHeight = Math.round(newWidth / aspectRatio);

            element.setAttribute('width', newWidth);
            element.setAttribute('height', newHeight);
        }
    });

    const figureElementsUpdated = doc.querySelectorAll('figure');
    figureElementsUpdated.forEach((element) => {
        element.removeAttribute('contenteditable');
        element.removeAttribute('data-trix-attachment');
        element.removeAttribute('data-trix-content-type');
        element.removeAttribute('data-trix-id');
        element.removeAttribute('data-trix-attributes');
        element.removeAttribute('data-trix-serialize');
        element.removeAttribute('class');
        element.removeAttribute('data-trix-mutable');
    });

    return doc.body.innerHTML;
}

export const checkFileChanges = (newFiles, prevFiles, setFiles, setPrevFiles) => {

    // Check if the new files are exactly one less than before
    if (
        newFiles.length === prevFiles.length - 1 &&
        newFiles.every((file) => prevFiles.includes(file))
    ) {
        console.log("File removed!");
        const removedFile = prevFiles.find(file => !newFiles.includes(file));

        if (removedFile) {
            console.log("Removed file:", removedFile);
            const updatedPrevFiles = prevFiles.filter(file => file !== removedFile);
            setPrevFiles(updatedPrevFiles);
            setFiles(updatedPrevFiles);
        }

    } else {
        // New files were added
        console.log("Files added");

        // Check if a file with the same name already exists
        const duplicateFile = newFiles.find((newFile) =>
            prevFiles.some((existingFile) => existingFile.name === newFile.name)
        );

        if (duplicateFile) {
            alert(`File with the name ${duplicateFile.name} already exists!`);
            return prevFiles; // Prevent adding duplicate files
        }

        // Create a new array that includes old files and new files
        const updatedFiles = [...prevFiles, ...newFiles];
        console.log("Updated Files:", updatedFiles);
        setPrevFiles(updatedFiles);

        // Update both the current files state and the allFiles state
        setFiles(updatedFiles);
    }
};

export const validateSerial = (inputSerialNumber, setSerialNumber, setSerialNumberError) => {
    // Regular expression for serial number format validation
    const serialNumberPattern = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[0-9]{5}-[0-9]{5}$/;

    if (!serialNumberPattern.test(inputSerialNumber)) {
        setSerialNumberError(true);
    } else {
        setSerialNumberError(false);
    }

    // Set the serial number state
    setSerialNumber(inputSerialNumber);
};

export const getResponseTime = (selectedPriority) => {

    switch (selectedPriority) {
        case 'Critical':
            return '2 hours';
        case 'High':
            return '24 hours';
        case 'Medium':
            return '3 working days';
        case 'Low':
            return '10 working days';
        default:
            return '';
    }
};
