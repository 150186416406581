import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import TerminalIcon from '@mui/icons-material/Terminal';

function VersionSelector({ productVersion, handleProductVersionChange }) {
    return (
        <FormControl variant="outlined" fullWidth >
            <InputLabel htmlFor="product-version" required>
                Product Version
            </InputLabel>
            <Select
                labelId="product-version-label"
                id="product-version"
                value={productVersion}
                onChange={handleProductVersionChange}
                label="Product Version"
                startAdornment={(
                    <InputAdornment position="start">
                        <TerminalIcon />
                    </InputAdornment>
                )}
            >

                //todo: fill this via api
                <MenuItem value="57961000029912647">zenon 14</MenuItem>
                <MenuItem value="57961000012006776">zenon 12</MenuItem>
                <MenuItem value="57961000005982717">zenon 11</MenuItem>
                <MenuItem value="57961000004169635">zenon 10</MenuItem>
                <MenuItem value="57961000004169631">zenon 8.20</MenuItem>
                <MenuItem value="57961000004169627">zenon 8.10</MenuItem>
                <MenuItem value="57961000004169623">zenon 8.00</MenuItem>
                <MenuItem value="57961000004169639">zenon 7.60</MenuItem>
                <MenuItem value="57961000004169783">zenon 7.50</MenuItem>
                <MenuItem value="57961000009317983">Other Version</MenuItem>
            </Select>
        </FormControl>
    );
}

export default VersionSelector;
