import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
    return (
        <AppBar className="title-bar" position="fixed"  style={{ top: 'auto', bottom: 0}}>
            <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>

                    <Typography variant="body2" color="inherit">
                        Brought to you with 🧡 from Salzburg | 📧 support-automation@copadata.com
                    </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Footer;
