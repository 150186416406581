import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

const UploadErrorDialog = ({ open, onClose }) => {

    const handleClick = () => {
        // Open the dynamic URL in a new tab

        //https://selfservice.copadata.com/portal/en/ticket/[ticket_id]
        window.open(`https://selfservice.copadata.com/portal/en/ticket/${localStorage.getItem("createdTicket")}`, '_blank');
    };

    const handleClose = () => {
        // Clean File cache
        localStorage.removeItem('localFileData');

        // Open the dynamic URL in a new tab
        window.location.href = "/";
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div style={{ display: 'flex' }}>

                <img
                    src="img/file-upload-error.webp"
                    alt="File Upload Error Icon"
                    style={{ marginRight: '10px', width: '200px', height:'200px', marginLeft:'20px', marginTop: '20px'}}
                />

                <DialogContent>
                    <Typography style={{ fontFamily: 'Arimo' }} variant="h6">
                        File Upload Error
                    </Typography>

                    <Typography style={{ fontFamily: 'Arimo', marginBottom:20 }}>
                        There was a problem while uploading your files. This could be the result of your internet connection having an issue while uploading.
                        Please upload the files in the Fileshare directly, the link can be found in your AR Ticket.
                    </Typography>

                    <Typography style={{ fontFamily: 'Arimo', marginBottom:20  }}>
                        However, <b>your AR Ticket has been created</b> for your request and will be promptly assigned to the most suitable expert.
                        Our team will review your inquiry and provide you with the necessary assistance as soon as possible.
                    </Typography>

                    <Typography style={{ fontFamily: 'Arimo', marginBottom:20  }}>
                        <b>Please don't forget to provide the files to get assistance as fast as possible.</b>
                    </Typography>

                    <Button onClick={handleClick} style={{ backgroundColor: '#202D4F', color: "white", marginTop: 10 }}>
                        Open Request in zoho
                    </Button>

                    <Button onClick={handleClose} style={{ backgroundColor: '#8C0000', color: "white", marginLeft:10, marginTop: 10 }}>
                        Close
                    </Button>

                </DialogContent>
            </div>
        </Dialog>

    );
};

export default UploadErrorDialog;
