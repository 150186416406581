// Helpers/GetBaseTicketData.js
import { handleLogout } from './Helpers';
import {MenuItem} from "@mui/material";
import React from "react";

export async function fetchBaseTicketData(ticketId) {
    try {

        const accessToken = localStorage.getItem("access_token");

        if(!accessToken){
            window.location.href = '/login';
            return;
        }

        const response = await fetch(`https://fileapi.copatools.com/api/TicketData/${ticketId}?accessToken=${accessToken}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
            },
        });

        const json = await response.json();

        //console.log("Response: ", json);

        if (response.status === 400 || response.status === 401) {
            console.log("Not Authorized");
            window.location.href = '/login';
            //handleLogout();
            return;

        } else if (response.status === 403) {
            alert("Only employees can access this data!");
            window.location.href = 'https://copadata.com/';
        } else if (response.ok) {
            localStorage.removeItem('localFileData');
            return json;
        } else {
            alert("We could not get the ticket data. This can be caused by issues with zoho. Please try again later.");
            throw new Error(`API request failed with status: ${response.status}`);
        }
    } catch (error) {
        throw error;
    }
}

export async function fetchFileshareData(ticketId, accessToken) {
    const apiUrl = `https://fileapi.copatools.com/api/Share/filelist?fileshareId=${ticketId}&auth=${accessToken}`; // Replace with the actual API URL

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`API request failed with status: ${response.status}`);
        }

        const data = await response.json();




        return data; // Assuming data is an array of strings
    } catch (error) {
        throw error;
    }
}